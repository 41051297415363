import * as React from 'react';
import { useState } from 'react';
import { Box, ListItemText } from '@mui/material';
import LabelIcon from '@mui/icons-material/Label';

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
    Menu,
    Count,
    ChipField
} from 'react-admin';

import fconflicts from '../fconflicts';
import fitment from '../fitment';
import nproducts from '../nproducts';
import nbrands from '../nbrands';
import nrules from '../nrules';
import conflicts from '../conflicts';
import SubMenu from './SubMenu';

type MenuName = 'menuData' | 'menuUpdates' | 'menuAPI';

const NMenu = ({ dense = true }: MenuProps) => {
    const [state, setState] = useState({
        menuData: true,
        menuUpdates: true,
        menuAPI: true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
            <Box
                sx={{
                    width: open ? 200 : 50,
                    marginTop: 1,
                    marginBottom: 1,
                    transition: theme =>
                        theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                }}
            >
                <DashboardMenuItem />
                <SubMenu
                    handleToggle={() => handleToggle('menuData')}
                    isOpen={state.menuData}
                    name="Data"
                    icon={<nproducts.icon />}
                    dense={dense}
                >
                    <MenuItemLink
                        to="/new-products"
                        state={{ _scrollToTop: true }}
                        primaryText="Products"
                        leftIcon={<nproducts.icon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/new-brands"
                        state={{ _scrollToTop: true }}
                        primaryText="Brands"
                        leftIcon={<nproducts.icon />}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/fitments"
                        state={{ _scrollToTop: true }}
                        primaryText="Fitment"
                        leftIcon={<nproducts.icon />}
                        dense={dense}
                    />
                </SubMenu>
                <SubMenu
                    handleToggle={() => handleToggle('menuUpdates')}
                    isOpen={state.menuUpdates}
                    name="Updates"
                    icon={<nproducts.icon />}
                    dense={dense}
                >
                    <MenuItemLink
                        to="/conflicts"
                        state={{ _scrollToTop: true }}
                        leftIcon={<nproducts.icon />}
                        dense={dense}
                    >
                        <ListItemText primary="Products" />
                        <Count resource='conflicts' filter={{ resolution: "unresolved" }} />
                    </MenuItemLink>
                    <MenuItemLink
                        to="/fitment-conflicts"
                        state={{ _scrollToTop: true }}
                        leftIcon={<nproducts.icon />}
                        dense={dense}
                        >
                        <ListItemText primary="Fitment" />
                        <Count resource='fitment-conflicts' filter={{ resolution: "unresolved" }} />
                    </MenuItemLink>
                </SubMenu>
                <MenuItemLink to="/new-rules" primaryText="Rules" leftIcon={<nproducts.icon />} dense={dense} />
                <MenuItemLink to="/dev" primaryText="Developer" leftIcon={<nproducts.icon />} dense={dense} />
            </Box>
    );
};

export default NMenu;