import {
    Admin,
    Resource,
    radiantLightTheme,
    radiantDarkTheme,
    ListGuesser,
    CustomRoutes,
    Button
} from 'react-admin';

import { Route } from 'react-router-dom';

import dataProvider from './dataProvider';
import { QTools } from './QTools';
import authProvider from './authProvider';
import nproducts from './nproducts';
import nbrands from './nbrands';
import Dashboard from './dashboard/dashboard';
import DeveloperTab from './dashboard/dev';
import sema from './sema';
import tokens from './tokens';
import nrules from './nrules';
import fitments from './fitment';
import bigcommerce from './bigcommerce';
import mapping from './Mapping';
import conflicts from './conflicts';
import fconflicts from './fconflicts';
import Settings from './Settings/settings';

function App() {
    return (
            <Admin
                disableTelemetry
                dashboard={Dashboard}
                dataProvider={dataProvider}
                authProvider={authProvider}
                lightTheme={radiantLightTheme}
                darkTheme={radiantDarkTheme}
                layout={QTools}
                title="SearchAUTO PIM">
                <CustomRoutes>
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/dev" element={<DeveloperTab />} />
                </CustomRoutes>
                
                <Resource name = "new-products" options={{ label:"Products" }} {...nproducts} />              
                <Resource name = "new-brands" options={{ label:"Brands" }} {...nbrands} /> 
                <Resource name = "fitments" options={{ label:"Fitment" }} {...fitments} />
                <Resource name = "new-rules" options={{ label:"Rules" }} {...nrules} />
                <Resource name = "sema-connection" options={{ label:"SEMA" }} {...sema} />
                <Resource name = "bigcommerce-connection" options={{ label:"BigCommerce" }} {...bigcommerce} />
                <Resource name = "mappings" options={{ label:"Mappings" }} {...mapping} />
                <Resource name = "api-tokens" options={{ label:"API Tokens" }} {...tokens} />
                <Resource name = "tags" recordRepresentation="name" />
                <Resource name = "conflicts" options={{ label: "Updates" }} {...conflicts} />
                <Resource name = "fitment-conflicts" options={{ label: "Fitment Updates" }} {...fconflicts} />
            </Admin>
    );
}

export default App;
